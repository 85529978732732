<template>
    <div class="home row">
        <div class="home-body col-xs-12 col-sm-6 col-md-4" v-for="(item,index) in homeList" :key="index">
            <q-card class="home-option" flat>
                <q-card-section>
                    <div class="card-title">{{ item.title }}</div>
                    <div class="card-des">
                        <p>{{ item.des }}</p>
                        <img :src="item.images" alt="">
                    </div>
                </q-card-section>
                <q-separator style="background: #d3d3d3"/>
                <q-card-actions>
                    <div class="card-btn">
                        <q-btn class="card-btn-star" @click="clickStart(item.link)" flat>开始</q-btn>
                    </div>
                </q-card-actions>
            </q-card>

        </div>
    </div>
</template>

<script>
    export default {
        name: "GroupQuestions",
        data(){
            return{
                homeList: [{
                    id: 1,
                    title: '知识点组题',
                    des: '根据题目的知识点分类，随机生成试卷，用于考生的专项训练;',
                    link: '/groupQuestionsList',
                    images:require('../assets/imgaes/6.png')
                },{
                    id: 1,
                    title: '考点分类组题',
                    des: '根据英皇考试几大板块（节奏、音高、调与音阶、音程、和弦、音乐术语、标记与乐器、乐谱分析)',
                    link: '/groupQuestionsList',
                    images:require('../assets/imgaes/7.png')
                }]
            }
        },
        methods:{
            clickStart(url){
                this.$router.push({path:url,query:{id:1}})
            }
        }
    }
</script>

<style scoped lang="less">
    .home {
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 15px;
        margin-top: 20px;
    }
    .home-body {
        margin-bottom: 20px;
    }
    .home-option {
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        margin-right: 15px;
    }
    .card-title {
        font-size: 24px;
        color: #333333;
        margin-bottom: 14px;
    }
    .card-des {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 44px;
        p {
            max-width: 217px;
            font-size: 12px;
            color: #999999;
            margin-bottom: 5px;
            /*margin-top: 27px;*/
        }
    }
    .card-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .card-btn-star {
            width: 70px;
            height: 37px;
            border: 1px solid #ED711C;
            border-radius: 19px;
            font-size: 13px;
            color: #ED711C;
        }
    }
</style>